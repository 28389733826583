@font-face {
    font-family: myAvenicNext;
    src: url(../Resources/AvenirNextLTPro-Regular.otf);
}
.header {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.title {
    color: white;
    font-family: myAvenicNext;
    font-size: 18px;
}