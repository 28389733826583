@font-face {
    font-family: myAvenicNext;
    src: url(/static/media/AvenirNextLTPro-Regular.b5ed9330.otf);
}
.Header_header__3pDs- {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Header_title__1uIIg {
    color: white;
    font-family: myAvenicNext;
    font-size: 18px;
}
@font-face {
    font-family: myAvenicNext;
    src: url(/static/media/AvenirNextLTPro-Regular.b5ed9330.otf);
}
.main-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black; 
    width: 100%; 
    height: 100vh;
}
.logo-style {
    width: 40%;
}

.text-style {
    height: 80px;
    width: 800px;
    color: #FFFFFF;
    font-family: myAvenicNext;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 44px;
    text-align: center;
}

.error-style {
    width: 800px;
    color: #FF7475;
    font-family: myAvenicNext;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 44px;
    text-align: center;
    margin-top: 70px;
}
@font-face {
    font-family: myAvenicNext;
    src: url(/static/media/AvenirNextLTPro-Regular.b5ed9330.otf);
}

.end_text {
    height: 80px;
    color: #FFFFFF;
    font-family: myAvenicNext;
    font-size: 32px;
    font-weight: bold;
    line-height: 100px;
    text-align: center;
}

  .box {
    background: linear-gradient(45deg,#2da6f4,#dd01b3 50%,#ff8a28);
    display: inline-block;
    outline: none;
    border: none;
    color: #fff;
    border-radius: 8px;
    padding: 12px 0.1rem 12px 0.1rem;
    cursor: pointer;
    box-shadow: 0 0 2px 1px rgb(0 0 0 / 10%);
  }
  .box span {
    font-size: 1.125rem;
    font-weight: 500;
    position: relative;
    border-radius: 8px;
    padding: 12px 1rem 12px 1rem;
    background: #130b27;
    width: 100%;
    height: 100%;
    text-align: center;
    font-family: myAvenicNext;
  }
