@font-face {
    font-family: myAvenicNext;
    src: url(../Resources/AvenirNextLTPro-Regular.otf);
}

.end_text {
    height: 80px;
    color: #FFFFFF;
    font-family: myAvenicNext;
    font-size: 32px;
    font-weight: bold;
    line-height: 100px;
    text-align: center;
}

  .box {
    background: linear-gradient(45deg,#2da6f4,#dd01b3 50%,#ff8a28);
    display: inline-block;
    outline: none;
    border: none;
    color: #fff;
    border-radius: 8px;
    padding: 12px 0.1rem 12px 0.1rem;
    cursor: pointer;
    box-shadow: 0 0 2px 1px rgb(0 0 0 / 10%);
  }
  .box span {
    font-size: 1.125rem;
    font-weight: 500;
    position: relative;
    border-radius: 8px;
    padding: 12px 1rem 12px 1rem;
    background: #130b27;
    width: 100%;
    height: 100%;
    text-align: center;
    font-family: myAvenicNext;
  }