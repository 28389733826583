@font-face {
    font-family: myAvenicNext;
    src: url(../Resources/AvenirNextLTPro-Regular.otf);
}
.main-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black; 
    width: 100%; 
    height: 100vh;
}
.logo-style {
    width: 40%;
}

.text-style {
    height: 80px;
    width: 800px;
    color: #FFFFFF;
    font-family: myAvenicNext;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 44px;
    text-align: center;
}

.error-style {
    width: 800px;
    color: #FF7475;
    font-family: myAvenicNext;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 44px;
    text-align: center;
    margin-top: 70px;
}